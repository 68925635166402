import React from 'react';
import './App.css';
import sujis from './data/sujis';

function SujiList({ tiles }) {
  return (
    <div className="suji-container">
      {Object.keys(sujis).map(suit => (
        <div key={suit} className="suji-column">
          {sujis[suit].map((suji, index) => {
            const isActive = suji.range.some(tile => tiles[tile]);
            return (
              <div key={index} className={`suji-item ${isActive ? 'inactive' : ''}`}>
                {suji.label}
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
}
export default SujiList;