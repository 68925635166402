import React from 'react';
import './App.css';
import MahjongTiles from './MahjongTiles';

function App() {
  return (
    <div className="App">
      <h1>Mahjong Suji Counter</h1>
      <MahjongTiles />
    </div>
  );
}

export default App;