const sujis = {
    'm': [
      { range: ['m1', 'm4'], label: 'm1-4' },
      { range: ['m4', 'm7'], label: 'm4-7' },
      { range: ['m2', 'm5'], label: 'm2-5' },
      { range: ['m5', 'm8'], label: 'm5-8' },
      { range: ['m3', 'm6'], label: 'm3-6' },
      { range: ['m6', 'm9'], label: 'm6-9' }
    ],
    'p': [
      { range: ['p1', 'p4'], label: 'p1-4' },
      { range: ['p4', 'p7'], label: 'p4-7' },
      { range: ['p2', 'p5'], label: 'p2-5' },
      { range: ['p5', 'p8'], label: 'p5-8' },
      { range: ['p3', 'p6'], label: 'p3-6' },
      { range: ['p6', 'p9'], label: 'p6-9' }
    ],
    's': [
      { range: ['s1', 's4'], label: 's1-4' },
      { range: ['s4', 's7'], label: 's4-7' },
      { range: ['s2', 's5'], label: 's2-5' },
      { range: ['s5', 's8'], label: 's5-8' },
      { range: ['s3', 's6'], label: 's3-6' },
      { range: ['s6', 's9'], label: 's6-9' }
    ]
  };
  
  export default sujis;