import React, { useEffect, useState, useCallback } from 'react';
import { useTiles } from './hooks/useTiles';
import TileButton from './TileButton';
import SujiList from './SujiList';
import './App.css';
import sujis from './data/sujis';

function MahjongTiles() {
  const [activeSujiCount, setActiveSujiCount] = useState(18);
  const [isFourPlayerMode, setIsFourPlayerMode] = useState(true); // 四人麻雀モードかどうかの状態
  const { tiles, toggleTile, resetTiles } = useTiles(isFourPlayerMode);

  const calculateActiveSujis = useCallback(() => {
    let count = 0;
    Object.keys(sujis).forEach(suit => {
      sujis[suit].forEach(suji => {
        const isActiveSuji = suji.range.some(tile => tiles[tile]);
        if (isActiveSuji) {
          count++;
        }
      });
    });
    setActiveSujiCount(count);
  }, [tiles]);

  useEffect(() => {
    calculateActiveSujis();
  }, [calculateActiveSujis]);

  const noSujiDanger = (1 / (18 - activeSujiCount) * 100).toFixed(2);

  const toggleMode = () => {
    setIsFourPlayerMode(!isFourPlayerMode);
  };

  return (
    <div>
      <div className="mode-label">
        <span>{isFourPlayerMode ? '四人麻雀' : '三人麻雀'}</span>
        <button onClick={toggleMode} className="mode-toggle-button button-mid">モード切替</button>
      </div>
      <div className="suji-count">{activeSujiCount}/18本</div>
      <div className="no-suji-danger">{noSujiDanger}% (1/{(18 - activeSujiCount)})</div>
      <SujiList tiles={tiles} />
      {['m', 'p', 's'].map(suit => (
        <div key={suit} className="suit-row">
          {[1, 2, 3, 4, 5, 6, 7, 8, 9].map(number => (
            <TileButton
              key={suit + number}
              tile={suit + number}
              onClick={() => toggleTile(suit + number)}
              pressed={tiles[suit + number]}
              disabled={!isFourPlayerMode && suit === 'm'} // 三人麻雀の場合、m1-9を無効化
            />
          ))}
        </div>
      ))}
      <button onClick={resetTiles} className="reset-button button-mid" >リセット</button>

    </div>
  );
}

export default MahjongTiles;