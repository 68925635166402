import React from 'react';

function TileButton({ tile, onClick, pressed, disabled }) {
  return (
    <button
      className={`tile-button ${pressed ? 'pressed' : ''} ${disabled ? 'disabled' : ''}`}
      onClick={onClick}
      disabled={disabled}
    >
      {tile}
    </button>
  );
}

export default TileButton;