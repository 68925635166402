import { useState, useEffect } from 'react';

const initialTiles = {
  'm1': false, 'm2': false, 'm3': false, 'm4': false, 'm5': false, 'm6': false, 'm7': false, 'm8': false, 'm9': false,
  'p1': false, 'p2': false, 'p3': false, 'p4': false, 'p5': false, 'p6': false, 'p7': false, 'p8': false, 'p9': false,
  's1': false, 's2': false, 's3': false, 's4': false, 's5': false, 's6': false, 's7': false, 's8': false, 's9': false
};

export function useTiles(isFourPlayerMode) {
  const [tiles, setTiles] = useState(initialTiles);

  useEffect(() => {
    if (!isFourPlayerMode) {
      const newTiles = { ...initialTiles }; // 初期状態からの更新を行う
      for (let i = 1; i <= 9; i++) {
        newTiles[`m${i}`] = true;
      }
      setTiles(newTiles);
    } else {
      setTiles(initialTiles); // 四人麻雀モードでは初期状態にリセット
    }
  }, [isFourPlayerMode]); // tiles を依存配列から削除

  const toggleTile = (tile) => {
    if (!isFourPlayerMode && tile.startsWith('m')) {
      return; // 三人麻雀モードで m1-m9 のタイルは切り替えを無効にする
    }
    setTiles(prev => ({ ...prev, [tile]: !prev[tile] }));
  };

  const resetTiles = () => {
    setTiles(initialTiles);
  };

  return { tiles, toggleTile, resetTiles };
}